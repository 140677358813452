/* .nav {
  height: 100vh;
  background-color: #f0f6ff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
} */

.logolist {
  margin-top: 40px;
  margin-bottom: 30px;
}

.logolist .imagelogodash {
  margin-bottom: 55px;
}

.navlist ul {
  list-style: none;
  margin-left: -40px;
  text-decoration: none;
}

.navlist .navlinke {
  margin-bottom: 10px;
  text-decoration: none;
  display: flex;
  width: 250px;
  height: 38px;
  gap: 20px;
  color: #858c90;
  font-size: 16px;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 7px;
}

.navlist .navlinke:hover {
  color: #0d6efd;
  background-color: #d7e6fa;
}

.navlist i {
  width: 15px;
}

.name {
  margin-bottom: 50px;
  margin-left: -80px;
}

.name h3 {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.name p {
  font-size: 12px;
  color: #3a434b;
  font-weight: 400;
}

/* 
.navlinke.active {

    font-weight: bold;

    color: #0D6EFD !important;
    background-color: #D7E6FA !important;



} */

/* @media screen and (max-width: 1000px) {
  .nav {
    display: none;
  }
} */
