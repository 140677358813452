/* src/styles/tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .academy-admin-input {
    @apply tw-w-[97%] tw-pl-[3%]  tw-text-[#0a1521] tw-h-[42px] tw-rounded-[6px] tw-mb-[15px] tw-bg-[#F7F9FB] tw-border-0 tw-text-[14px];
  }
}
.error {
  color: rgb(122, 2, 2);
}

.success {
  color: rgb(1, 47, 1);
}
