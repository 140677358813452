.details {
  border-radius: 8px;
  border: 2px solid #eaedf4;
  width: fit-content;

  display: flex;
  width: 96%;
  padding: 10px;
  flex-direction: column;
}

.firstend {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eaedf4;
  padding-bottom: 20px;
}

.secondend {
  display: flex;
  width: 46%;
  justify-content: space-between;
  padding-top: 20px;
}

.mustbecolumn {
  display: flex;
  flex-direction: column;
}

.inputorganization {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000;

  font-size: 16px;

  font-weight: 400;
  line-height: 16px;
  margin-top: 3px;
}

.inputorganization::placeholder {
  color: #000;

  font-size: 16px;

  font-weight: 400;
  line-height: 16px;
}
