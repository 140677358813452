.navlogin{

    width: 100%;
    background: linear-gradient(136deg, rgba(255, 255, 255, 0.70) 0%, #0D6EFD 100%);
    height: 100px;
    display: flex;
    align-items: center;
}


.wrappe{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.loginholder{

    width: 608px;
    height: 400px;
    border-radius: 24px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    
}

.mainholder {

 
    height: 80%;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


}



.head .h2{

    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #0D6EFD;
}

.head p{

    font-size: 16px;
    font-weight: 400;
    color: #100F0F;
    text-align: center;
    margin-top: -15px;
}

.inputdiv {

    display: flex;
    flex-direction: column;
   
    margin-bottom: 10px;
    width: 400px;
}

.inputdiv label{

    text-align: left;
}

.inputdiv input{

    border: 1px solid #B0BABF;
    height: 30px;
    margin-top: 10px;
    border-radius: 4px;
}


.loginbtn{

    width: 400px ;
    background-color: #0D6EFD;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 7px;
    border: 1px solid #0D6EFD;
    margin-top: 30px;
    cursor: pointer;
    
}

input:focus{

 outline-color: #0D6EFD;
 
    
       
}