.filenotindiv{


    
    width: 150px;
    height: 150px;

   display: flex;
   flex-direction: column;
}

.filenotindiv h3{

    text-align: center;
}

.filenotindiv .img{

    width: 70%;
    height: 90%;
    align-self: center;
}

.holder{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    
}

.scholarshipnav{

    border-bottom: 1px solid #858C90 ;
    display: flex;
    gap :20px;
    cursor: pointer;
}

.scholarshipnav .firstTab{

color: #0D6EFD;
border-bottom: 2px solid #0D6EFD;
cursor: pointer;

}


.scholarshipnav .tabu {

    color: #B0BABF;
        border: none;
        cursor: pointer;

}

.scholarshipnav  .activeSendMoney{

    color: #0D6EFD;
    border-bottom: 2px solid #0D6EFD;
    cursor: pointer;

}

.aprrovecard{

    width: 304px;
    height: 186px;
    border-radius: 10px;    
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.innerapprove{

    width: 90%;
  
    height: 80%;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.aprrovecard .cardname{

    color: #000;
    
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
}

.aprrovecard p{
    
    color: #B0BABF;
    font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-top: -10px;

}

.approve{

    border-radius: 8px;
        background: #0D6EFD;
        width: 100px;
        height: 40px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
}

.decline{

    border-radius: 8px;
        background: transparent;
        width: 100px;
        height: 40px;
        color: #B0BABF;
        border: none;
        align-items: center;
        justify-content: center;


}

.thebuttons{

    display: flex;
    justify-content: space-between;    
}

.fileindiv{

   
    display: flex;
    margin-top: 20px;
}
