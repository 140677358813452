.questiontag {

    align-self: flex-end;
    color: #0D6EFD;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-right: 10px;
   
}


.headerquiz{

    text-align: center;
}
.questionta{

    align-self: flex-start;
    margin-left: 10px;
        color: #0D6EFD;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
}



.question {

    font-size: 24px;
    text-align: center;


}

.options {
    list-style: none;
    display: flex;
    margin-top: -10px;
    flex-direction: column;
    gap: 20px;
    width: 70%;

}

.options li {

    margin-left: -30px;
    cursor: pointer;
    border-radius: 15px;
    padding: 3px;
    padding-left: 10px;
    color: #0D6EFD;
    font-size: 16px;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);


}



.nextButton:disabled {

    opacity: 0.5;
    cursor: not-allowed;

}


.option.clicked {
    border-radius: 10px;
    background: #0D6EFD;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
    color: white;

}



.navquiz {

    width: 100%;
    background:#025EE6;
    height: 100px;
    display: flex;
    align-items: center;
}

.welcomehead {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

}

.welcome {

    width: 500px;
    min-height: 400px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.welcome button {

    background: linear-gradient(136deg, rgba(255, 255, 255, 0.70) 0%, #0D6EFD 100%);
    border: none;
    width: 150px;
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;

}


.welcome button:hover {

    background: #0D6EFD;

}

.headerquizend{

    font-size: 14px;
    text-align: center;
    margin-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
}



/* #AccesscodeErrorpage */

#oopss {
background: rgb(2, 94, 230);
    background: linear-gradient(90deg, rgba(2, 94, 230, 1) 46%, rgba(221, 226, 234, 1) 100%);
    position: fixed;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 1.5em;
    z-index: 9999;
}

#oopss #error-text {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Shabnam', Tahoma, sans-serif;
    color: #000;
    direction: rtl;
}

#oopss #error-text img {
    margin: 15px auto 0px;
    height: 200px;
}

#oopss #error-text span {
    position: relative;
    font-size: 34px;
    font-weight: 900;
    margin-bottom: 10px;
}

#oopss #error-text p.p-a {
    font-size: 30px;
    margin: 30px 0 15px 0;
}

#oopss #error-text p.p-b {
    font-size: 15px;
}

#oopss #error-text .back {
    background: #fff;
    color: #000;
    font-size: 20px;
    text-decoration: none;
    margin: 2em auto 0;
    padding: 15px 10px;
    border-radius: 500px;  
    font-weight: 600;
    transition: all 300ms ease;
}

#oopss #error-text .back:hover {
    -webkit-transform: translateY(-13px);
    transform: translateY(-13px);
    box-shadow: 0 35px 90px 4px rgba(0, 0, 0, 0.3), inset 0px 0 0 3px #000;
}

@font-face {
    font-family: Shabnam;
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot");
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff") format("woff"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: Shabnam;
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot");
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff") format("woff"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.ttf") format("truetype");
    font-weight: normal;
}



/* accesscodenotfoundendher */

@media screen and (max-width:522px) {

    .welcome {

        width: 80%;
    }
}