/* @import url("https://fonts.googleapis.com/css2?family=Inter&family=Manrope&family=Montserrat&family=Poppins&family=Roboto:wght@100&family=Source+Sans+3&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */

/* body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  font-feature-settings: "clig" off, "liga" off;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

.chuksbutton a {
  text-decoration: none;
  color: white;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

select:focus {
  outline: none;
  /* box-shadow: 0 0 0 1px #3498db;  */
  border-radius: rgb(170, 163, 163)
}

/* Quiz Page */
.quiz-cover {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
}

.quiz-page {
  display: inline-flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.quiz-diamond {
  position: absolute;
  left: 14px;
  display: none;
}

.quiz-diamond-two {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: none;
}

.quiz-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 48px;
}

.quiz-title {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 50.4px;
}

.quiz-subtile {
  color: var(--Black, #100f0f);
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.quiz-form-label {
  color: var(--Black, #010f0f);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-quiz-hold {
  width: 100%;
  margin-bottom: 20px;
}

.quiz-form-input {
  width: 100%;
  height: 40px;
  border-radius: 3.907px;
  border: 0.781px solid #b0babf;
  background: var(--White, #fff);
  margin-bottom: 2px;
  padding: 4px;
  font-size: 16px;
}

.quiz-btn {
  width: 100%;
  padding: 4px;
  height: 40px;
  margin-top: 28px;
  border-radius: 7.815px;
  background: #0d6efd;
  color: #fff;
  border: 0px;
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .quiz-form-input {
    width: 500px;
  }

  .quiz-page {
    border-radius: 0px 24px 24px 0px;
    padding: 56px 54px 47px 54px;
    max-width: 750px;
  }

  .quiz-diamond {
    display: block;
  }

  .quiz-diamond-two {
    display: block;
  }
  .quiz-title {
    font-size: 32px;
  }

  .quiz-subtile {
    font-size: 16px;
  }

  .form-quiz-hold {
    width: 500px;
  }
}

/* Quiz page */
.question-no {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 50.4px; /* 315% */
}

.quiz-teaser {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 50.4px; /* 157.5% */
  margin-top: -13px;
}

.quiz-divider {
  width: 100%;
  background: linear-gradient(
    136deg,
    #b1c6e7 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  height: 8px;
  z-index: 9999;
  position: absolute;
  top: 230px;
}

.quiz-progress {
  background: #0d6efd;
  height: inherit;
}

.quiz-container {
  padding-top: 38px;
}

.quiz-question {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 50.4px; /* 210% */
  margin-top: -40px;
  margin-bottom: 32px;
  text-align: center;
}

.quiz-option {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  gap: 18px;
  height: 59px;
  padding-left: 20px;
  margin-bottom: 18px;
  cursor: pointer;
}

.selected-ans {
  background: #0d6efd;
}

.selected-ans .option-answer {
  color: #fff;
}

.selected-ans .option-letter {
  color: #fff;
  background: #0d6efd;
}

.option-letter {
  background: linear-gradient(
    136deg,
    #b1c6e7 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #0d6efd;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.13);
}

.option-answer {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50.4px; /* 210% */
}

.quiz-footer {
  display: flex;
  width: 100%;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
}

.previous-quiz {
  border-radius: 10px;
  background: linear-gradient(
    136deg,
    #b1c6e7 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
  color: #0d6efd;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  border: 0px;
  width: 40%;
  height: 59px;
  cursor: pointer;
}

.next-quiz {
  border-radius: 10px;
  background: #0d6efd;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
  width: 40%;
  height: 59px;
  border: 0px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.form-quiz-cont {
  color: #100f0f;
  text-align: left;
  width: 100%;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-quiz-instruc li {
  color: #100f0f;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quiz-timer {
  position: absolute;
  top: 105px;
  right: 140px;
  color: #858c90;
  font-size: 24px;
  font-weight: 600;
  line-height: 50.4px;
}

@media screen and (min-width: 767px) {
  .quiz-option {
    width: 100%;
  }

  .quiz-timer {
    top: 168px;
    right: 108px;
  }
}

/* UAA */
.uaa-container {
  padding: 30px 0px;
}

.uaa-header {
  color: #0d6efd;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.28px;
}

.uaa-subheader {
  color: #0d0f11;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 20px;
}

/*  Admin Quiz Setting */
.quiz-setting-all {
  display: inline-flex;
  align-items: flex-end;
  margin-top: 14px;
  padding: 0px 12px;
  border-bottom: 1px solid #e0e7ea;
  justify-content: center;
  width: 100%;
  justify-content: space-between;
}

.quiz-setting-header a {
  text-decoration: none;
  color: #b0babf;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.028px;
}

.quiz-setting-header {
  padding: 10px 20px;
}

.quiz-setting-header:hover a {
  color: #0d6efd;
}

.quiz-setting-header-active {
  border-bottom: 1px solid #0d6efd;
}

.generate-link-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 98px;
}

.generate-link-all {
  display: inline-flex;
  width: 500px;
  padding: 49px 52px 50px 53px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.gen-link-title {
  color: #0d6efd;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 50.4px;
}

.gen-link-btn {
  border-radius: 7.815px;
  background: #0d6efd;
  border: none;
  padding: 8px 85px 8px 87px;
  color: #fff;
  margin-top: 45px;
  cursor: pointer;
}

.gen-link-output {
  background-color: #f7f9fb;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 70px;
  height: 40px;
  align-items: center;
  padding: 1px 10px;
}

.gen-link-copy {
  width: 16%;
  color: #025ee6;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gen-link-copy i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gen-link-input {
  width: 70%;
  border: none !important;
}

/* Add Quiz Questions */
.add-quiz-title {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.add-quiz-text {
  color: #0d6efd;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}

.add-quiz-btn {
  display: flex;
  width: 182px;
  height: 40px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #025ee6;
  text-decoration: none;
  color: #025ee6;
}

.quiz-table-empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.admin-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
}

.message-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  padding: 20px;
  border-radius: 10px;
}

.quiz-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quiz-modal-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.quiz-modal-header-title {
  color: #0d6efd;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}

.quiz-modal-header-close {
  color: #000;
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-list-quiz {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.visibility-hidden {
  visibility: hidden;
}

.modal-list-quiz img {
  margin-right: 4px;
}

.quiz-scores-up {
  color: #b0babf;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quiz-model-label {
  color: #010f0f;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
}

.quiz-modal-input {
  border-radius: 3.907px;
  border: 0.781px solid #dde2ea;
  background: #fff;
  padding: 10px;
  height: 43px;
  color: #010f0f;
}

.input-w-300 {
  width: 300px;
}

.input-w-150 {
  width: 126px;
}

.quiz-input-group {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 6.44px;
}

.quiz-modal-btn {
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  background: #0d6efd;
  width: 339px;
  height: 40px;
  border: 0;
  cursor: pointer;
}

.modal-quiz-left {
  padding: 30px 0px;
}

.quiz-modal-footer {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.modal-quiz-right {
  padding: 30px 0px;
}

.header-quiz-up {
  display: flex;
  justify-content: space-between;
}

.row-group {
  display: flex;
  gap: 24px;
}

.cover-input-time {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.781px solid #dde2ea;
  border-radius: 3.907px;
  padding-right: 8px;
}

.quiz-modal-body input:focus {
  border-color: rgb(170, 163, 163) !important;
}

.list-quiz-item {
  display: flex;
  justify-content: space-between;
  padding: 13px 25px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
  margin-top: 14px;
}

.quiz-item-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 37px;
}

.quiz-item-no {
  display: flex;
  background: linear-gradient(
    136deg,
    #b1c6e7 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  width: 47px;
  height: 47px;
  justify-content: center;
  align-items: center;
  color: #0d6efd;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 50.4px;
  border-radius: 50%;
}

.p-cursor {
  cursor: pointer;
}

.ad-question-overlay {
  position: absolute;
  width: 200px;
  height: 100px;
  right: 30px;
  background-color: white;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 1px;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.08);
}

.popup-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
}

.triangle {
  width: 0;
  height: 0;
  display: flex;
  border-bottom: 25px solid rgb(237, 237, 237);
  border-left: 12px transparent solid;
  border-right: 12px transparent solid;
  position: absolute;
  z-index: 9;
  right: 89px;
}

.ad-question-opt:hover {
  color: #0d6efd;
}

.ad-question-opt {
  color: #b0babf;
  cursor: pointer;
  font-family: Manrope;
  font-size: 14px;
  margin-top: 16px;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.028px;
  text-decoration: none;
}

.uaa-title-image {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0d6efd;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  text-decoration: none;
  margin-top: 30px;
}

.saved-questions {
  color: #010f0f;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 83.333% */
}

.saved-questions-no {
  color: var(--Grey, #b0babf);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.028px;
}

.table-quiz {
  width: 100%;
  margin-top: 49px;
}

.table-quiz thead tr th {
  background: #f7f9fb !important;
  color: #6d7a98;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.table-quiz td {
  border-bottom: 2px solid #f7f9fb;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;
  color: #0b2253;
  padding-top: 20px;
  padding-bottom: 20px;
}

.add-question-title {
  color: var(--Grey, #b0babf);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin-top: 32px;
}

.quiz-answer-option {
  color: var(--Black, #010f0f);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
}

.quiz-select-tag {
  border-radius: 6px;
  border: 0.5px solid #025ee6;
  width: 185px;
  height: 40px;
  padding: 10px 21px;
  color: #025ee6;
}

.quiz-answer-text {
  color: #1b2023;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  padding: 5px;
}

.answer-lists {
  border-radius: 3.907px;
  border: 0.781px solid #dde2ea;
  background: #fff;
  /* width: 100%; */
  padding: 13px 8px;
  display: flex;
  gap: 14px;
}

.gap-20 {
  gap: 20px;
}

.answer-list input:focus,
.answer-list input:active {
  border: 0 !important;
  border-bottom: 1px solid red !important;
}

.answer-list img {
  cursor: pointer;
}

.ph-color textarea::placeholder,
.answer-list input::placeholder {
  color: #b0babf;
}

.display-none {
  display: none;
}

.upload-file-title {
  color: #010f0f;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
}

.be-none {
  background: #fff;
  border: 1px solid #025ee6;
  color: #025ee6;
}

.cursor-pointer {
  cursor: pointer;
}

.add-scholarship {
  color: #010f0f;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sch-student-list {
  border-radius: 10px;
  border: 1px solid #b1c6e7;
  background: #fff;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26px 16px;
  margin-bottom: 20px;
}

.sch-student-name {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize !important;
}

.sch-student-email {
  color: #b0babf;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mt-12 {
  margin-top: 12px !important;
}

.appDetailsFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 39.4px;
}

.approveBtn {
  border-radius: 8px;
  background: #0d6efd;
  padding: 8px 24px;
  border: 0;
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.declineBtn {
  background: none;
  border: 0;
  color: var(--Grey, #b0babf);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.font-14 {
  font-size: 14px;
}

.text-decoration-none {
  text-decoration: none;
}

.adjust-tr tr {
  border-collapse: collapse;
}

.adjust-tr td,
.adjust-tr th {
  padding: 0px 5px;
}

/* add course */
.outline-fix input:focus {
  outline: none;
}

.addtopic {
  background-color: transparent;
  color: #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: 1px solid #0d6efd;
  cursor: pointer;
  border-radius: 5px;
  height: 32px;
  margin-top: 10px;
  cursor: pointer;
}

.ck-content {
  background: #f7f9fb !important;
  height: 93px;
}

.ck-powered-by__label,
.ck-powered-by a,
.ck-powered-by {
  display: none !important;
  visibility: hidden;
}

/* .navlist .active {
  background: #0d6efd !important;
  color: #fff !important;
}

.navlist .active i {
  color: #fff !important;
} */

:root {
  --tg-body-font-family: "Hind", sans-serif;
  --tg-heading-font-family: "Lexend Deca", sans-serif;
  --tg-icon-font-family: "Font Awesome 5 Free";
  --tg-body-font-size: 16px;
  --tg-body-line-height: 1.75;
  --tg-heading-line-height: 1.2;
  --tg-common-color-blue: #2494e4;
  --tg-common-color-indigo: #9b51e0;
  --tg-common-color-purple: #8121fb;
  --tg-common-color-pink: #d63384;
  --tg-common-color-red: #ff2e2e;
  --tg-common-color-orange: #fd7e14;
  --tg-common-color-yellow: #fab123;
  --tg-common-color-green: #12bb6a;
  --tg-common-color-teal: #219653;
  --tg-common-color-cyan: #0dcaf0;
  --tg-common-color-white: #fff;
  --tg-common-color-gray: #e7effc;
  --tg-common-color-gray-2: #f4f7fb;
  --tg-common-color-gray-3: #f5f7fb;
  --tg-common-color-dark: #061e43;
  --tg-common-color-black: #000;
  --tg-body-color: #39557e;
  --tg-heading-color: #082a5e;
  --tg-theme-primary: #1363df;
  --tg-theme-secondary: #082a5e;
  --tg-border-1: #ececec;
  --tg-border-2: #d0dae9;
  --tg-border-3: #e2e2e2;
  --tg-border-4: #d7dce3;
  --tg-border-5: #2f466a;
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
}

.common-color-dark {
  background-color: #061e43;
}

@media (prefers-color-scheme: light) {
  :root {
    --tg-body-font-family: "Hind", sans-serif;
    --tg-heading-font-family: "Lexend Deca", sans-serif;
    --tg-icon-font-family: "Font Awesome 5 Free";
    --tg-body-font-size: 16px;
    --tg-body-line-height: 1.75;
    --tg-heading-line-height: 1.2;
    --tg-common-color-blue: #2494e4;
    --tg-common-color-indigo: #9b51e0;
    --tg-common-color-purple: #8121fb;
    --tg-common-color-pink: #d63384;
    --tg-common-color-red: #ff2e2e;
    --tg-common-color-orange: #fd7e14;
    --tg-common-color-yellow: #fab123;
    --tg-common-color-green: #12bb6a;
    --tg-common-color-teal: #219653;
    --tg-common-color-cyan: #0dcaf0;
    --tg-common-color-white: #fff;
    --tg-common-color-gray: #e7effc;
    --tg-common-color-gray-2: #f4f7fb;
    --tg-common-color-gray-3: #f5f7fb;
    --tg-common-color-dark: #061e43;
    --tg-common-color-black: #000;
    --tg-body-color: #39557e;
    --tg-heading-color: #082a5e;
    --tg-theme-primary: #1363df;
    --tg-theme-secondary: #082a5e;
    --tg-border-1: #ececec;
    --tg-border-2: #d0dae9;
    --tg-border-3: #e2e2e2;
    --tg-border-4: #d7dce3;
    --tg-border-5: #2f466a;
    --tg-fw-extra-bold: 800;
    --tg-fw-bold: 700;
    --tg-fw-semi-bold: 600;
    --tg-fw-medium: 500;
    --tg-fw-regular: 400;
    --tg-fw-light: 300;
  }
}

.ReactModal__Overlay {
  z-index: 9;
  background-color: rgb(36 36 36 / 75%) !important;
}

.ReactModal__Content {
  background: url("../public/summer_bg.png") !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: 0 !important;
}

@media (max-width: 767.98px) {
  .ReactModal__Content {
    background: url("../public/summer_bg_small.png") !important;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: 0 !important;
  }
}
