.courseHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.courseHeading .titLEEE h1 {
  color: #0d6efd;
  font-size: 28px;
  font-weight: 700;
}

.courseHeading .title h2 {
  color: #0d0f11;
  font-size: 20px;
  font-weight: 700;
}

.searchbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchbar .addbtn {
  background-color: #0d6efd;
  padding: 10px 16px;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  width: 150px;
  align-self: flex-end;
  cursor: pointer;
}

.addcourselink {
  text-decoration: none;
  color: #fff;
}

.searchbar input {
  border: 1px solid #d7dce0;
  border-radius: 6px;
  width: 384px;
  height: 35px;
  padding-left: 10px;
  font-size: 16px;
}

.searchbar input::placeholder {
  color: #677684;
  font-size: 14px;
  padding: 10px;
}

.table {
  margin-top: 50px;
  border-collapse: collapse;
  width: 100%;
}

.table th {
  border: none;
  padding: 0;
  background-color: #f0f0f0;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: #6d7a98;
  padding-left: 10px;
}

.table td {
  border-bottom: 2px solid #f7f9fb;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  color: #0b2253;
  padding-top: 20px;
  padding-bottom: 20px;
}

.threestoke {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.lasttable {
  position: relative;
}

.actions {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 5;
  width: 100px;
  align-items: center;
  cursor: pointer;
}

.actions .action {
  color: #b0babf;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 15px;
}

.actioncourse {
  text-decoration: none;
  color: #b0babf;
  font-size: 14px;
  font-weight: 700;
}

.actioncourse:hover {
  color: #0d6efd;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.pagination button {
  background-color: transparent;
  border: none;
  color: #6d7a98;
  font-weight: 400;
}

.current_page {
  width: 30px;
  height: 30px;
  background-color: #edf1fd;
  color: #1d4ed8;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationbutton {
  display: flex;
  padding-right: 40px;
}

.time {
  color: #9da9b3;
}

.pagination h2 {
  color: #6d7a98;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.pagination h2 span {
  color: #0b2253;
}

/* courses.css */

/* Add these styles at the end of your existing CSS file */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-modal {
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 7.52055px 16.71233px 0px rgba(171, 180, 189, 0.23);
  border-radius: 4px;
  text-align: center;
}

.headwarning {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7dce0;
  align-items: center;
  padding: 0;
}

.deletemodalhtwo {
  color: #3a434b;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.deleteX {
  font-size: 20px;
}

.delete-modal p {
  margin-top: 20px;
  margin-bottom: 40px;
}

.delete-modal button {
  margin: 0 10px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

/* Add any other styles you already have for the rest of the components */

.delete {
  border-radius: 8px;
  background: #0d6efd;
  width: 100px;
  height: 40px;
  color: white;
  font-size: 16px;
}

.cancel {
  background-color: transparent;
  border: none;
  color: #b0babf;
  font-size: 16px;
}

.barCompleted {
  background-color: #059302;
  width: 80%;
}

/* .container {
  background: linear-gradient(
    136deg,
    #0d6efd 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
} */

.StandardAdvancetab {
  width: 90%;
  margin-top: 15px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
}

.tab {
  background-color: #e2e8f0;
  width: 50%;
  color: #666666b0;
  height: 100%;
  display: flex;
  align-items: center;
  border: 5px, 0px, 0px, 5px;
  justify-content: center;
  cursor: pointer;
}

.activecoursetype {
  background-color: #0d6efd;
  color: white;
}

.standarddiv {
  display: flex;
  flex-direction: column;
}

.standarddiv .labelito {
  margin-top: 10px;
}

.standarddiv .inputdis {
  margin-top: -5px;
}

.inputdis::placeholder {
  color: #b0babf;
}

.imputimage {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  align-items: center;
}

.holdinginputss {
  display: flex;
  flex-direction: column;
}
.subtopicdiv {
  display: flex;
  flex-direction: column;
  background-color: #059302;
  height: 50px;
}

.imputimage div {
  background-color: #f7f9fb;
  height: 40px;
  display: flex;
  align-items: center;
}

.imputimage div input {
  background-color: #f7f9fb;
  border: none;
}

.imputimage input:focus {
  outline: none;
}

.addsubtopic {
  background-color: #f7f9fb;
  color: #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border: 1px solid #f7f9fb;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}

.addtopic {
  background-color: transparent;
  color: #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: 1px solid #0d6efd;
  cursor: pointer;
  border-radius: 5px;
  height: 32px;
  margin-top: 10px;
  cursor: pointer;
}

.overlayupper {
  background-color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
