

.wrapperSucess{


    margin-top: 100px;

    display: flex;
    justify-content: center;
}

.success{

    max-width: 600px;
    

    padding: 5%;
    height: 200px;

    margin-top: 20px;

    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px 5px rgba(144, 144, 158, 0.5);

    border-radius: 10px;
}

.success  ion-icon{
    color: #1774fd;
    font-size: xxx-large;
    font-weight: bolder;
   
  }