.headingcoursedetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.headingcoursedetails h2 {
  color: #0d6efd;
  font-size: 28px;
  font-weight: 700;
}

.btnback {
  color: #0d6efd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
.headingcoursedetails .viewback button {
  color: #0d6efd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: -12px;
}

.academy-admin-btn {
  display: flex;
  height: 40px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #0d6efd;
  border: none;
  cursor: pointer;
  color: white;
}

.cover {
  border: 1px solid #eaedf4;
  border-radius: 8px;
  height: 500px;

  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.sideone {
  display: flex;
  flex-direction: column;
}

.imgspot {
  width: 80px;
  height: 50px;
  background-color: #80b1f8;
  border-radius: 8px;
}

.labelito {
  color: #6d7a98;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.117px;
  margin-bottom: 15px;
}

.inputdis {
  width: 400px;
  color: #f7f9fb;
  height: 42px;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #f7f9fb;
  border: none;
  color: #000;
  font-size: 14px;
  padding-left: 10px;
}

.academy-admin-input:focus {
  outline-color: #f7f9fb;
}

.sidetwo {
  width: 100%;
  margin-left: 10px;
}

textarea {
  width: 400px;
  color: #f7f9fb;
  height: 42px;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #f7f9fb;
  border: none;
  color: #000;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
  text-align: justify;
  scrollbar-width: thin;
  scrollbar-color: #333 #f7f9fb;
}

/* Track */
textarea::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar track */
}

/* Thumb (the draggable part of the scrollbar) */
textarea::-webkit-scrollbar-thumb {
  background-color: #333;
  /* Color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

/* Handle (the non-draggable part of the scrollbar) */
textarea::-webkit-scrollbar-track {
  background-color: #f7f9fb;
  /* Color of the track */
  border-radius: 6px;
  /* Rounded corners for the track */
}

/* Corner (between the horizontal and vertical scrollbar) */
textarea::-webkit-scrollbar-corner {
  background-color: #f7f9fb;
  /* Color of the corner */
}

textarea:focus {
  outline-color: #f7f9fb;
  border: none;
}

.upload {
  margin-top: 20px;
  display: flex;
  gap: 30px;
}
