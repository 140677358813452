.full {
  margin-top: 50px;

  padding-right: 15px;
}

.heading h2 {
  font-size: 28px;
  color: #0d6efd;
  font-weight: 700;
}

.heading p {
  font-size: 16px;
  font-weight: 400;
  color: #b0babf;
  margin-bottom: 30px;
}

.threebox {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.square,
.squaret,
.squarer {
  width: 208px;
  height: 132px;
  border: 6px;
  box-shadow: 10px 10px 100px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 22px;
}

.square p,
.squaret p,
.squarer p {
  font-size: 16px;
  color: #7f7572;
}

.square i,
.squaret i,
.squarer i {
  width: 30px;
}

.square .nuicon,
.squaret .nuicon,
.squarer .nuicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.square {
  background: rgba(224, 235, 250, 0.5);
  border: 2px solid #0d6efd;
}

.squaret {
  background: rgba(228, 207, 18, 0.05);
  border: 2px solid #e4cf12;
}

.squarer {
  background: rgba(255, 216, 216, 0.34);
  border: 2px solid #c91919;
}

.graphAnddetails {
  display: flex;
  width: 98%;
}

.graphAnddetails > * {
  flex: 1;
}

.headgrad {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headgrad h3 {
  color: #0d6efd;
  font-size: 20px;
  font-weight: 500;
}

.headgrad p {
  color: #b0babf;
  font-size: 16px;
  font-weight: 500;
  margin-top: -20px;
}

.headgrad select {
  height: 25px;
  width: 113px;
  background-color: #f5f6fa;
  border: none;
}

.headgrad select:focus {
  outline-color: #f5f6fa;
}

.divrect {
  width: 100%;
  height: 99px;
  background-color: #fff;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.divrect p {
  width: 150px;
}

.piechartlegent {
  display: flex;
  flex-direction: column;
  margin-top: -100px;
}

.heedgrad {
  color: #0d6efd;
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
}

.piechartlegent .eachdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topstodenttopcourses {
  display: flex;
  width: 100%;
  gap: 10px;
}

.topstodenttopcourses > * {
  flex: 1;
}

.topcourses,
.topstudent {
  border: 1px solid #dde5ee;
}

@media screen and (max-width: 1000px) {
  .full {
    width: 100%;
    align-self: center;
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .heading {
    align-self: start;
    margin-left: 15px;
  }

  .threebox {
    flex-direction: column;
    align-self: center;
    align-items: center;
    gap: 30px;
  }

  .graphAnddetails {
    flex-direction: column;
  }

  .topstodenttopcourses {
    flex-direction: column;
  }

  .square,
  .squaret,
  .squarer {
    width: 96%;
  }

  .square,
  .squaret,
  .squarer {
    width: 90%;
    align-self: center;
  }
}
